import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const BrownSugarKitchen = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const image = getImage(first_video.fields.thumbnail);

  const description = "Watch me make recipes from Tanya Holland's Brown Sugar Kitchen cookbook, which includes food served at her restaurant of the same name, where she features a modern take on soul food.";

  return (
    <Layout>
      <Seo title="Brown Sugar Kitchen" image={image} meta={metaData} description={description}/>
      <h1>Brown Sugar Kitchen</h1>
      <h2> New-Style, Down-Home Recipes from Sweet West Oakland</h2>
      <p><a href="https://www.tanyaholland.com/">Tanya Holland</a> is an award winning chef,
      author, and restauranteur. She was a contestant on the fifteeth season of 
      <a href="https://www.bravotv.com/top-chef"> Top Chef</a>, and has appeared on several 
      other television shows. She's also written for a number of publications, and has a
      podcast, <a href="https://www.instagram.com/tanyastablepodcast/">Tanya's Table</a>. She 
      now has a cooking show on the <a href="https://www.oprah.com/app/own-tv.html">OWN Network</a>, 
      <a href="https://www.oprah.com/app/tanyas-kitchen-table.html"> Tanya's Kitchen Table</a>.
      Her book, <i>Brown Sugar Kitchen</i> contains recipes for some of the food she serves at her 
      restaurant with the same name.</p>
    
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/brown sugar kitchen/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default BrownSugarKitchen;
